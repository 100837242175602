// import clsx from 'clsx';
// import React, { useState, useRef } from 'react';
// // import useOffSetTop from 'src/hooks/useOffSetTop';
// import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
// import NotificationsIcon from '@material-ui/icons/Notifications';

// import SpeedIcon from '@material-ui/icons/Speed';


// import AccountCircle from '@material-ui/icons/AccountCircle';
// import MenuIcon from '@material-ui/icons/Menu';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import LogoutIcon from '@mui/icons-material/Logout';
// import GroupsIcon from '@mui/icons-material/Groups';
// import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
// // import homeFill from '@iconify-icons/eva/home-fill';
// import PopoverMenu from 'src/components/PopoverMenu';
// // import roundSpeed from '@iconify-icons/ic/round-speed';
// // import menu2Fill from '@iconify-icons/eva/menu-2-fill';
// import { PATH_APP } from 'src/routes/paths';
// // import bookOpenFill from '@iconify-icons/eva/book-open-fill';
// // import roundStreetview from '@iconify-icons/ic/round-streetview';
// import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// import { makeStyles, alpha } from '@material-ui/core/styles';
// import {
//   Box,
//   List,
//   Link,
//   Badge,
//   AppBar,
//   Hidden,
//   Toolbar,
//   Button,
//   Container,
//   ListItemIcon,
//   ListItemText,
//   IconButton,
//   Typography,
//   Divider
// } from '@material-ui/core';
// import Menu from '@mui/material/Menu';
// import MessageIcon from '@mui/icons-material/Message';

// import { styled } from '@mui/material/styles';
// import { useDispatch } from 'react-redux';
// import { logout } from 'src/redux/slices/authJwt';
// import qrata from '../qrataLogo.svg'

// const useStyles = makeStyles((theme) => ({
//   root: {},
//   AppBar: {
//     boxShadow: 'none',
//     zIndex: theme.zIndex.drawer + 1,
//     [theme.breakpoints.down('sm')]: {
//       top: 'auto',
//       bottom: 0
//     }
//   },
//   mobileHeader: {
//     backgroundColor: '#fff',
//     top: 0,
//     bottom: 'auto'
//   },
//   mobileToolbar: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center'
//   },
//   toolbar: {
//     // height: APP_BAR_MOBILE,
//     transition: theme.transitions.create(['height', 'background-color'], {
//       easing: theme.transitions.easing.easeInOut,
//       duration: theme.transitions.duration.shorter
//     }),
//     [theme.breakpoints.up('md')]: {
//       //   height: APP_BAR_DESKTOP
//     }
//   },
//   toolbarContainer: {
//     lineHeight: 0,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between'
//   },
//   isHome: {
//     color: 'rgba(255, 255, 255, 0.6)',
//     marginRight: theme.spacing(2)
//   },
//   isDesktopActive: {
//     color: '#21C8AA'
//   },
//   isMobileActive: {
//     color: theme.palette.primary.main,
//     fontWeight: theme.typography.fontWeightMedium
//   },
//   onScroll: {
//     '& $toolbar': {
//       backgroundColor: theme.palette.background.default
//     },
//     '& $isHome': {
//       color: theme.palette.text.primary
//     },
//     [theme.breakpoints.up('md')]: {
//       '& $toolbar': {
//         // height: APP_BAR_DESKTOP - 20
//       }
//     }
//   }
// }));

// function TopBarWithSignUp({ children }) {
//   const classes = useStyles();

//   const MENU_LINKS = [
//     {
//       title: 'Communities',
//       icon: <GroupsIcon/>,
//       href: PATH_APP.general.communities
//     },
//     {
//       title: 'Opportunities',
//       icon: <ExploreOutlinedIcon />,
//       href: PATH_APP.general.opportunities
//     },
//     {
//       title: 'Standout',
//       icon: <MessageIcon />,
//       href: PATH_APP.general.standout
//     }
//   ];
  
//   return (
//     <React.Fragment>
//       <AppBar color="primary" className={classes.AppBar}>
//         <Toolbar disableGutters className={classes.toolbar}>
//           <Container className={classes.toolbarContainer} maxWidth="lg">
//           <RouterLink
//                 to="/"
//                 style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', gap: '10px' }}
//               >
//                 <img src={qrata} alt="logo" width='75px' />
//                 {/* <Divider
//                   orientation="vertical"
//                   flexItem
//                   color="secondary"
//                   style={{ width: '2px', backgroundColor: '#21C8AA' }}
//                 /> */}
//                 <div style={{width: '1px', background: 'white', height: '25px'}}></div>
//                 <Typography style={{ color: 'white' }}>
//                   <span style={{ fontSize: '20px' }}>For Talent</span>
//                 </Typography>
//           </RouterLink>
//             {/* <Hidden mdDown sx={{ flexGrow: 2 }}> */}
//             {/* {renderMenuDesktop} */}
//             {/* </Hidden> */}

//             {/* <Box sx={{ flexGrow: 1 }} /> */}
//             <Box>
//               {/* <IconButton aria-label="show 17 new notifications" color="inherit">
//               <Badge badgeContent={17} color="secondary">
//                 <NotificationsIcon />
//               </Badge>
//             </IconButton> */}
//               <RouterLink
//                 to="/auth/login"
//                 style={{ color: 'white', textDecoration: 'none'  , border:"1px solid #fff" , borderRadius:"12px" ,  padding:"6px 8px" ,marginRight:"8px"}}
//               >
//                 <Button
//                   edge="end"
//                   aria-controls="demo-customized-menu"
//                   aria-haspopup="true"
//                   // onClick={handleClick}
//                   color="inherit"
//                 >
//                   Login
//                 </Button>
//               </RouterLink>
//               <RouterLink
//                 to="/auth/register"
//                 style={{ color: 'white', textDecoration: 'none' ,border:"1px solid #fff" , borderRadius:"12px" , padding:"6px 8px"}}
//               >
//                 <Button
//                   edge="end"
//                   aria-controls="demo-customized-menu"
//                   aria-haspopup="true"
//                   // onClick={handleClick}
//                   color="inherit"
//                 >
//                   Sign Up
//                 </Button>
//               </RouterLink>
              
//             </Box>
//           </Container>
//         </Toolbar>
//         {
//           <Box
//             sx={{
//               left: 0,
//               right: 0,
//               bottom: 0,
//               height: 24,
//               zIndex: -1,
//               margin: 'auto',
//               borderRadius: '50%',
//               position: 'absolute',
//               width: `calc(100% - 48px)`,
//               boxShadow: (theme) => theme.shadows[25].z8
//             }}
//           />
//         }
//       </AppBar>
//       <Box>{children}</Box>
//     </React.Fragment>
//   );
// }

// export default TopBarWithSignUp;


import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import GroupsIcon from '@mui/icons-material/Groups';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import MessageIcon from '@mui/icons-material/Message';
import qrata from '../qrataLogo.svg';
import { PATH_APP } from 'src/routes/paths';

const useStyles = makeStyles((theme) => ({
  AppBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 0,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuLinks: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    textDecoration: 'none',
    position: 'relative',
    // '&.active': {
    //   color: '#fff',
    //   '&::after': {
    //     content: '""',
    //     position: 'absolute',
    //     bottom: -4,
    //     left: 0,
    //     right: 0,
    //     height: 2,
    //     backgroundColor: '#21C8AA',
    //   },
    // },
    '&:hover': {
      color: '#21C8AA',
    },
  },
  icon: {
    color: 'white',
  },
  hoverIcon: {
    '&:hover': {
      color: '#21C8AA',
    },
  },
}));

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  border: '1px solid #fff',
  borderRadius: '12px',
  padding: '6px 8px',
  marginRight: '8px',
  '&:hover': {
    color: '#21C8AA',
    border: '1px solid #21C8AA',
  },
}));

function TopBarWithSignUp({ children }) {
  const classes = useStyles();

  const MENU_LINKS = [
    {
      title: 'Communities',
      icon: <GroupsIcon className={`${classes.icon} ${classes.hoverIcon}`} />,
      href: "/Communities",
    },
    {
      title: 'Opportunities',
      icon: <ExploreOutlinedIcon className={`${classes.icon} ${classes.hoverIcon}`} />,
      href: "/opportunities",
    },
    {
      title: 'Standout',
      icon: <MessageIcon className={`${classes.icon} ${classes.hoverIcon}`} />,
      href: "/standout",
    },
  ];

  return (
    <React.Fragment>
      <AppBar color="primary" className={classes.AppBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Container className={classes.toolbarContainer} maxWidth="lg">
            <RouterLink
              to="/"
              style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', gap: '10px' }}
            >
              <img src={qrata} alt="logo" width="75px" />
              <div style={{ width: '1px', background: 'white', height: '25px' }}></div>
              <Typography style={{ color: 'white' }}>
                <span style={{ fontSize: '20px' }}>For Talent</span>
              </Typography>
            </RouterLink>
            {/* <List className={classes.menuLinks}>
              {MENU_LINKS.map((link) => (
                <RouterLink to={link.href} className={classes.menuItem} activeClassName="active" key={link.title}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.title} />
                </RouterLink>
              ))}
            </List> */}
            {/* <Box>
              <StyledRouterLink to="/auth/login">
                <Button color="inherit">Login</Button>
              </StyledRouterLink>
              <StyledRouterLink to="/auth/register">
                <Button color="inherit">Sign Up</Button>
              </StyledRouterLink>
            </Box> */}
          </Container>
        </Toolbar>
        <Box
          sx={{
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            margin: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            boxShadow: (theme) => theme.shadows[25].z8,
          }}
        />
      </AppBar>
      <Box>{children}</Box>
    </React.Fragment>
  );
}

export default TopBarWithSignUp;
