import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const communityApiBaseUrl = process.env.REACT_APP_API_COMM_URL;
console.log(communityApiBaseUrl)
const initialState = {
  isLoading: false,
  error: null,
  communities: [],
};

const allCommunitiesSlice = createSlice({
  name: 'allCommunities',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAllCommunitiesSuccess(state, action) {
      state.isLoading = false;
      state.communities = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  getAllCommunitiesSuccess,
} = allCommunitiesSlice.actions;

export default allCommunitiesSlice.reducer;

// Thunk to fetch all communities data
export function fetchAllCommunities() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_COMM_URL}/c/allcommunity`);
      dispatch(getAllCommunitiesSuccess(response.data));
    } catch (error) {
      console.error('Error fetching all communities:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while fetching all communities data.';
      dispatch(hasError(errorMessage));
    }
  };
}
export function deleteCommunity(communityId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_COMM_URL}/c/community/${communityId}`);
      dispatch(getAllCommunitiesSuccess(response.data));
    } catch (error) {
      console.error('Error fetching all communities:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while fetching all communities data.';
      dispatch(hasError(errorMessage));
    }
  };
}
