import axios from 'axios';
import { createSlice, current } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  jobList: [],
  totalJobs: null,
  individualJob: null,
  error: null,
  appliedJobs: [],
  filterDropdown: {
    location: [],
    skills: [],
    company: [],
    role: [],
  }
};

const slice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    matchedOpportunitiesSuccess(state, action) {
      state.isLoading = false;
      state.jobList = action.payload;
    },
    updateAppliedJobs(state, action) {
      console.log('action payload', current(state), action.payload);
      let appliedJob = state.jobList.find(job => job._id === action.payload);
      console.log("appliedJob", appliedJob)
      state.appliedJobs = [...state.appliedJobs, appliedJob];
      state.isLoading = false;
    },
    getPageSuccess(state, action) {
      console.log('filter job', current(state.jobList), action.payload);
      let updatedJob = action.payload.jobs.map((job) => {
        let isCandidate = job.applications.find((application) =>
          // console.log('application id ', application);
          application.talentId.$oid === action.payload.talentId ? true : false
        );

        if (isCandidate) {
          return {
            ...job,
            isCandidateApplied: true
          };
        }

        return {
          ...job,
          isCandidateApplied: false
        };
      });
      console.log(
        'filter job',
        current(state.jobList),
        action.payload,
        updatedJob
      );
      state.isLoading = false;
      state.totalJobs = action.payload.totalJobs;
      state.jobList.push(...updatedJob);
    },
    filterOpportunitiesSuccess(state, action) {
      console.log('filter job', current(state.jobList), action.payload);
      let updatedJob = action.payload.jobs.map((job) => {
        let isCandidate = job.applications && job.applications.find((application) =>
          // console.log('application id ', application);
          application.talentId.$oid === action.payload.talentId ? true : false
        );

        if (isCandidate) {
          return {
            ...job,
            isCandidateApplied: true
          };
        }

        return {
          ...job,
          isCandidateApplied: false
        };
      });
      
      console.log('Opportunities state after update:', state);
      console.log(
        'filter job',
        current(state.jobList),
        action.payload,
        updatedJob
      );
      state.isLoading = false;
      state.totalJobs = action.payload.totalJobs;
      state.jobList = updatedJob;
    },
    getApplicationsSuccess(state, action) {
      state.isLoading = false;
      console.log("is it here : ", action.payload)
      state.appliedJobs = action.payload.appliedJobs;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setJobId(state, action) {
      state.job_id = action.payload;
    },
    filterOptions(state, action) {
      console.log("filter options", action.payload);
      state.filterDropdown.role = action.payload.map(role =>({
        key: role,
        value: role,
        label: role
      }))

      
    }
  }
});

export const { setJobId } = slice.actions;

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getMatchedopportunities(talentCard) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://matching.qrata.ai/match',
        talentCard
      );
      dispatch(
        slice.actions.matchedOpportunitiesSuccess(
          response.data['matched candidate']
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFilteropportunities(filter, talentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/talent/jobs/filter',
        filter
      );

      if (filter.page > 1) {
        dispatch(
          slice.actions.getPageSuccess({
            jobs: response.data,
            talentId,
            totalJobs: response.data.length
          })
        );
      } else {
        dispatch(
          slice.actions.filterOpportunitiesSuccess({
            jobs: response.data,
            talentId,
            totalJobs: response.data.length
          })
        );
      }
    
    } catch (error) {
      console.log('errorr', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterDropdownOptions(){
  return async (dispatch) => {
    try {
      const response = await axios.get('https://hireapi.qrata.ai/api/options/role');
      console.log("response", response.data);
      let allSubRoleData = [];
       response.data.map(role => {
        allSubRoleData = [...allSubRoleData, ...role.subCategories];
       });

       console.log("modified", allSubRoleData);
       dispatch(slice.actions.filterOptions(allSubRoleData))
    }
    catch (err) {
      console.log(err);
    }
  }
}
// ----------------------------------------------------------------------

export function applyJob(job_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setJobId(job_id));
    try {
      const response = await axios.post(`/api/talent/jobs/apply/${job_id}`);
      dispatch(slice.actions.updateAppliedJobs(job_id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyApplications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/talent/jobs/applied-jobs`);
      dispatch(slice.actions.getApplicationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
